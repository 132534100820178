import React, {setState} from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import {Col, Row, Container} from 'react-bootstrap';

class BlogRoll extends React.Component {

  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <section className='sec-mrg'>
        <Container fluid className=''>
          <Row>

            {posts &&
              posts.map(({ node: post }, index) => 
              {
            return (
                <Col  xs={{ span: post.frontmatter.column, offset: post.frontmatter.columnoffset}}
                key={post.id}
                style={{marginTop: Math.floor(Math.random() * (100 - 20)) + 20}}
                className='work'
                >
                  <div className='work-container'>
                  <Link
                    // className="title has-text-primary is-size-4"
                    to={post.fields.slug}
                  >
                    
                    <div
                      // className={`blog-list-item tile is-child box notification ${
                        className={`tile is-child box notification ${
                        post.frontmatter.featuredpost ? 'is-featured' : ''
                      }`}
                    >
                    <div>

                      {post.frontmatter.featuredimage ? (
                        <div className="featured-thumbnail position-relative">

                          {/* Title Box */}
                              <div className='work-over-container mask'>
                                <div className='h-100 w-100 d-flex justify-content-center align-items-center align-items-center position-relative'>
                                  <div className='text-center'>
                                    <div  className='work-item-title'>
                                      <h1 className='max-w-80'>{post.frontmatter.title}</h1>
                                    </div>
                                  </div> 
                                </div>
                              </div>

                          <PreviewCompatibleImage
                            imageInfo={{
                              image: post.frontmatter.featuredimage,
                              alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                            }}
                            className='featured'
                          />
                        </div>
                      ) : null}

                        {/* Details */}
                        <div className='work-item-details w-100 zindex-2 clearfix'>
                          <h6 className='float-left'>{post.frontmatter.year}</h6>
                          <h6 className='float-right'>{post.frontmatter.commissioner}</h6>
                        </div> 


                      {/* {post.frontmatter.title} */}
                    </div>
                  </div>
                </Link>
                </div>
              </Col>
              )
              }
              )
              }    
          </Row>
        </Container>
      </section>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                commissioner
                year
                templateKey
                column
                columnoffset
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 100, traceSVG: {
                      color: "SpringGreen",
                      optTolerance: 0.4,
                      turdSize: 100,
                      turnPolicy: TURNPOLICY_MINORITY
                      blackOnWhite: false
                    }) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }        
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
